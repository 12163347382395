<template>
  <section class="section-home-hero">
    <div class="page-padding">
      <div class="padding-top padding-huge">
        <div class="padding-bottom padding-xhuge">
          <div class="container-xlarge">
            <div class="product-hero-grid large">
              <div id="w-node-_1ca4e8a3-8819-7a33-b28a-35e3d813f71b-c907603c"
                   class="product-hero-grid-right">
                <div data-w-id="1ca4e8a3-8819-7a33-b28a-35e3d813f71c" class="card muted">
                  <div class="card-padding small">
                    <div class="margin-bottom margin-xsmall">
                      <h4 class="margin-bottom margin-medium">{{ $t('message.feedback.search.title', locale) }}</h4>
                    </div>
                    <div class="margin-bottom margin-medium">
                      <div data-w-id="8f9836ef-040c-bb3d-e095-8b19e7622ddb"
                           class="form-wrapper">

                        <div class="form-field-wrapper">
                          <div class="form-label">Tendance</div>
                          <v-select
                              :items="sort_options"
                              :clearable="true"
                              item-value="id"
                              item-text="text"
                              v-model="sort"
                              :label="$t('message.search.filters.sortBy.label', locale)"
                              @change="changeType"
                              rounded
                              attach
                              single-line
                              class="form-input w-select"
                          ></v-select>
                        </div>
                        <div class="form-field-wrapper">
                          <div class="form-label">Catégorie</div>
                          <v-select
                              :items="categoryList"
                              :clearable="true"
                              item-value="id"
                              item-text="title"
                              v-model="category"
                              label="Catégorie"
                              @change="changeType"
                              rounded
                              attach
                              single-line
                              class="form-input w-select"
                          ></v-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="w-node-_1ca4e8a3-8819-7a33-b28a-35e3d813f725-c907603c"
                   class="product-hero-grid-left">
                <h2 class="margin-bottom">{{ $t('message.feedback.head.title', locale) }}</h2>
                <p class="text-size-regular">{{ $t('message.feedback.head.content', locale) }}</p>
                <div>
                  <a :href="urlAdd" class="button secondary w-inline-block">
                    <div class="button-inner">
                      <div class="button-inner-text">{{ $t('message.feedback.head.buttons.add', locale) }}</div>
                      <div class="button-inner-text-hover">{{ $t('message.feedback.head.buttons.add', locale) }}</div>
                    </div>
                  </a>
                </div>
                <div class="w-dyn-list">
                  <div role="list" class="availabilities-grid w-dyn-items">
                    <div v-for="(fb, index) in feedback" :key="index" role="listitem" class="w-dyn-item">
                      <div class="margin-top margin-small">
                        <div data-w-id="b05ee670-0585-6fbd-18ed-fd52d7478990"
                             class="card muted">
                          <div class="card-padding">
                            <div class="margin-bottom margin-small">
                              <h3 class="margin-bottom margin-small">
                                <a :href="fb.url">{{ fb.title }}</a>
                              </h3>
                              <p class="text-size-regular">Catégorie : {{ fb.category }}</p>
                              <p class="text-size-small">{{ fb.date }}</p>
                            </div>
                            <div class="margin-top margin-small">
                              <div class="post-author-grid">
                                <a id="w-node-_0afac987-75a4-e398-b077-bcfa4f22ad3d-c907603c"
                                   :href="fb.user.url" target="_blank" class="w-inline-block"><img loading="lazy"
                                                                                                   id="w-node-b05ee670-0585-6fbd-18ed-fd52d74789a4-c907603c"
                                                                                                   :src="fb.user.avatar" alt=""
                                                                                                   class="post-author-image"></a>
                                <div id="w-node-b05ee670-0585-6fbd-18ed-fd52d74789a5-c907603c">
                                  <div class="margin-bottom margin-tiny">
                                    <a :href="fb.user.url" target="_blank" class="w-inline-block">
                                      <h4 class="heading-h5"> {{ fb.user.fullName }}</h4>
                                    </a>
                                  </div>
                                  <div class="text-meta-small text-color-muted">
                                    {{ fb.user.profession }} • {{ fb.user.location }}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="margin-top margin-medium">
                              <a :href="fb.url" class="button w-inline-block">
                                <div class="button-inner">
                                  <div class="button-inner-text">{{ $t('message.feedback.head.buttons.read', locale) }}
                                  </div>
                                  <div class="button-inner-text-hover">{{ $t('message.feedback.head.buttons.read', locale) }}</div>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <infinite-loading
                        :identifier="infiniteId"
                        @infinite="infiniteHandler"
                    >
                      <div slot="no-more"></div>
                      <div slot="no-results">
                        <div class="background-color-muted w-dyn-empty">
                          <div>{{ $t('message.feedback.search.notFound', locale) }}</div>
                        </div>
                      </div>
                    </infinite-loading>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import InfiniteLoading from "vue-infinite-loading";

const routes = require("../../../../public/js/fos_js_routes.json");
import Routing from "../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";
import axios from "axios";
import sort_options from "../dashboard/datas/sort_options_suggestion.json";

Routing.setRoutingData(routes);

export default {
  components: {},
  props: {},
  data: function () {
    return {
      page: 1,
      feedback: [],
      infiniteId: +new Date(),
      isUserLoggedIn: "",
      category: "",
      categoryList: [],
      status: "",
      statusList: [],

      disabled: true,
      items: [],
      search: null,
      urlAdd: null,
      defaultOrder: "createdAt",
      locale: null,
      sort: "voting",
      sort_options: sort_options,
    };
  },
  created() {
  },
  mounted: function () {
    this.locale = $('html').attr('lang');
  },
  methods: {
    addVote(index) {
      if (!this.isUserLoggedIn) {
        this.$notify({
          type: 'error',
          text: 'Vous devez être connecté pour voter',
          duration: 5000, // durée en millisecondes
          speed: 1000, // vitesse de transition en millisecondes
          showClose: true, // afficher le bouton fermer
        })
        return;
      }

      if (this.feedback[index].isOwner) {
        this.$notify({
          type: 'error',
          text: 'Vous ne pouvez pas voter pour votre propre idée.',
          duration: 5000, // durée en millisecondes
          speed: 1000, // vitesse de transition en millisecondes
          showClose: true, // afficher le bouton fermer
        })
        return
      }

      const fb = this.feedback[index];
      fb.isLoading = true;

      axios.post(Routing.generate("suggestion_box_votes", {feedbackId: this.feedback[index].id}))
          .then(response => {
            if (response.status === 200 && response.data.success === true) {
              this.feedback[index].countVotes += 1;
              fb.showHeart = true;
              fb.liked = true;
            } else {
              console.error('Erreur lors de l\'envoi du vote : ' + response.status);
            }
          })
          .catch(error => {
            console.error('Erreur lors de l\'envoi du vote : ' + error);
          })
          .finally(() => {
            fb.isLoading = false;
          });
    },


    infiniteHandler($state) {
      this.$http
          .get(
              Routing.generate("json_front_feedback", {
                page: this.page,
              }),
              {
                params: {
                  page: this.page,
                  sort: this.sort,
                  category: this.category,
                  status: this.status,
                },
              }
          )
          .then(({data}) => {
            if (data.feedback.length > 0) {
              this.page += 1;
              this.feedback.push(...data.feedback);
              if (this.page > data.totalPages) {
                $state.complete();
              }
              this.categoryList = data.categoryList;
              this.statusList = data.statusList;
              $state.loaded();
            } else {
              $state.complete();
            }
            this.isUserLoggedIn = data.isUserLoggedIn;
            this.urlAdd = data.urlAdd;
          });
    },
    changeType() {
      this.page = 1;
      this.feedback = [];
      this.infiniteId += 1;
    },
  },
};
</script>

<style>

.vote-wrapper {
  position: relative;
}

.whouaou {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: #FF69B4;
  animation: whouaou 1s;
}

@keyframes whouaou {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  50% {
    opacity: 1;
    transform: scale(1.2);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}
</style>
