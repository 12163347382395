<template>
  <v-app>
    <go-top
        bg-color="#1976d2"
        src="/assets/img/icon-backtotop.png"
        :size="50"
        :has-outline="false"
    ></go-top>
    <div class="padding-top">
        <div class="container-xlarge">
          <div id="w-node-_1ca4e8a3-8819-7a33-b28a-35e3d813f725-88ee08b8" class="product-hero-grid-left">
            <div class="w-dyn-list">
              <div role="list" class="annuaire-grid w-dyn-items">
                <div v-for="(user, $index) in users" :key="$index" role="listitem" class="w-dyn-item">
                  <div id="w-node-_9cd56d24-5299-eafa-2c62-048babbe4559-88ee08b8">
                    <div class="div-block-7">
                      <template v-if="user.identity.isPro">
                        <div class="div-block-8">
                          <div class="label primary">
                            <div>Pro</div>
                          </div>
                        </div>
                      </template>
                      <a :href="user.identity.url" target="_blank" class="link-block w-inline-block">
                        <div id="w-node-f31acf5e-d582-cb4b-8609-3b42aa06e293-88ee08b8"
                             data-w-id="f31acf5e-d582-cb4b-8609-3b42aa06e293"
                             style="-webkit-transform:translate3d(0, 0, 0) scale3d(1.1, 1.1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 0, 0) scale3d(1.1, 1.1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 0, 0) scale3d(1.1, 1.1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 0, 0) scale3d(1.1, 1.1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)"
                             class="profile-picture"
                             :style="{backgroundImage:`url(${user.identity.avatar})`}"></div>
                      </a>
                      <template v-if="user.identity.availabilities.length > 0">
                        <div class="div-block-5">
                          <a :href="user.identity.link_availability" target="_blank" class="label muted">
                            <div>{{ user.identity.availabilities.length }} disponibilité(s)</div>
                          </a>
                        </div>
                      </template>
                    </div>
                    <a :href="user.identity.url" target="_blank" class="margin-top margin-small w-inline-block">
                      <div class="div-block-6">
                        <h4 class="heading-h4">{{ user.identity.fullName }}</h4>
                      </div>
                    </a>
                    <h6 class="margin-top">{{ $t(user.identity.experience, 'fr') }}</h6>
                    <div class="text-size-small">{{ user.identity.location }}</div>
                  </div>
                </div>
                <infinite-loading
                    :identifier="infiniteId"
                    @infinite="infiniteHandler"
                >
                  <div slot="no-more"></div>
                  <div slot="no-results">
                    <div class="background-color-muted w-dyn-empty">
                      <div>{{ $t('message.directory.filters.empty', 'fr') }}</div>
                    </div>
                  </div>
                </infinite-loading>
              </div>
            </div>
          </div>
        </div>
      </div>
  </v-app>
</template>

<script>
import axios from "axios";

const routes = require("../../../../public/js/fos_js_routes.json");
import Routing from "../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";
import 'semantic-ui-vue';
import "@mdi/font/css/materialdesignicons.css";
import VueSelect from "vue-select";
import job_options from "../dashboard/datas/job_options.json";
import experience_options from "../dashboard/datas/experience_options.json";
import stylePhotos_options from "../dashboard/datas/stylePhotos_options.json";
import sex_options from "../dashboard/datas/sex_options.json";
import ethnicity_options from "../dashboard/datas/ethnicity_options.json";
import hairs_color_options from "../dashboard/datas/hairs_color_options.json";
import eyes_color_options from "../dashboard/datas/eyes_color_options.json";
import sort_options from "../dashboard/datas/sort_options.json";

Routing.setRoutingData(routes);

export default {
  components: {
    "vue-select": VueSelect
  },
  data: function () {
    return {
      totalItems: 0,
      page: 1,
      users: [],
      infiniteId: +new Date(),
      disabled: true,
      isLoading: false,
      items: [],
      autocomplete: null,
      search: null,
    };
  },
  mounted: function () {
    this.locale = $('html').attr('lang');
  },
  methods: {
    infiniteHandler($state) {
      this.$http
          .get(
              Routing.generate("json_new_portfolios", {
                page: this.page,
              }),
              {
                params: {
                  page: this.page,
                },
              }
          )
          .then(({data}) => {
            if (data.users.length > 0) {
              this.page += 1;
              this.users.push(...data.users);
              this.totalItems = data.totalItems;
              if (this.page > data.totalPages) {
                $state.complete();
              }
              $state.loaded();
            } else {
              $state.complete();
            }
          });
    },
  },
};
</script>

<style>
.v-application a {
  color: inherit !important;
}

.v-input--selection-controls {
  margin-top: 0 !important;
  padding-top: 0 !important;
}

.v-text-field--rounded > .v-input__control > .v-input__slot {
  padding-left: 0;
  padding-right: 0;
}

.w-dropdown {
  z-index: 7;
}

label {
  text-transform: none;
  margin-bottom: 0;
}

.button-small.button-header.search {
  min-height: 55px;
}

.formulate-input {
  width: 100%;
}

.v-input--switch--inset .v-input--switch__track {
  left: 0;
}

.v-input--switch__thumb {
  margin-left: 5px;
}

.v-input--switch .v-input--selection-controls__input {
  margin-left: 11px;
}
</style>
