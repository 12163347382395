<template>
  <div id="w-node-_57fd8a3b-573b-0796-3ee0-acb067d21b50-83acdcf7" class="blog-posts-grid-right-copy" @contextmenu.prevent="handleRightClick">
    <go-top
        bg-color="#1976d2"
        src="/assets/img/icon-backtotop.png"
        :size="50"
        :has-outline="false"
    ></go-top>
    <div v-for="(feed, $index) in feeds" class="services-hero-image-wrapper-copy">
      <div class="div-block-26">
        <div class="card muted full-height">
          <div class="services-hero-card-inner">
            <div class="div-block-29">
              <h5><a :href="feed.user.url" target="_blank">{{ feed.user.firstname }}</a> a publié {{ feed.count }}
                <template v-if="feed.count > 1">nouvelles photos</template>
                <template v-else>nouvelle photo</template>
                dans la galerie “<a
                    :href="feed.gallery.url" target="_blank">{{ feed.gallery.name }}</a>”
              </h5>
              <div class="margin-bottom margin-xsmall">
                <div class="text-meta text-color-muted">{{ feed.timeAgo }}</div>
              </div>
            </div>

            <div class="slider-3 w-slider" id="w-node-bb0e979a-e665-32c9-3f2b-e90462b9f6fa-83acdcf7">
              <div class="about-team-grid-copy w-slider-mask" v-if="feed.images">
                <VueSlickCarousel :arrows="true" :dots="false" :slidesToShow="3" :slidesToScroll="1" :infinite="false" :responsive="[{ breakpoint: 1024,settings: {slidesToShow: 1,}}]">

                  <template #prevArrow="arrowOption">
                    <div class="arrow-button w-slider-arrow-left">
                      <div class="w-icon-slider-left"></div>
                    </div>
                  </template>
                  <template #nextArrow="arrowOption">
                    <div class="arrow-button right w-slider-arrow-right">
                      <div class="w-icon-slider-right"></div>
                    </div>
                  </template>

                  <template v-for="(image, $index) in feed.images">
                    <v-img
                        class="grey lighten-2 about-team-image-wrapper-copy w-slide me-5"
                        @click="openModal(image)"
                        :src="image.path"
                        :lazy-src="image.path"
                        style="cursor: pointer;"
                    >
                      <template v-slot:placeholder>
                        <v-row class="fill-height ma-0" align="center" justify="center">
                          <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </template>
                </VueSlickCarousel>
              </div>
            </div>
            <div class="div-block-32">
              <div class="div-block-34">
                <div class="post-author-grid">
                  <a id="w-node-f52d3bf7-62b7-b364-3d7b-789866cca89f-83acdcf7"
                     :href="feed.user.url" target="_blank" class="w-inline-block">
                    <img loading="lazy"
                         id="w-node-f52d3bf7-62b7-b364-3d7b-789866cca8a0-83acdcf7"
                         alt=""
                         :src="feed.user.avatar"
                         class="post-author-image xlarge"></a>
                  <div id="w-node-f52d3bf7-62b7-b364-3d7b-789866cca8a1-83acdcf7"
                       class="div-block-31">
                    <div class="margin-bottom">

                      <a :href="feed.user.url" target="_blank" class="margin-top w-inline-block identity-wrapper">
                        <div class="div-block-6">
                          <h4 class="heading-h4">{{ feed.user.fullName }}</h4>
                        </div>
                        <template v-if="feed.user.isPro">
                          <div class="label primary small">
                            <div>Pro</div>
                          </div>
                        </template>
                      </a>
                      <h6 class="margin-top">{{ feed.user.profession }}</h6>
                      <div class="text-size-small">{{ feed.user.address.fullAddress }}</div>

                    </div>
                  </div>
                </div>
              </div>
              <a :href="feed.gallery.url" target="_blank" class="button secondary w-inline-block">
                <div class="button-inner">
                  <div class="button-inner-text">{{ $t('message.all_photos', locale_request) }}</div>
                  <div class="button-inner-text-hover">{{ $t('message.all_photos', locale_request) }}</div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <infinite-loading
        :identifier="infiniteId"
        @infinite="infiniteHandler"
    >
      <div slot="no-more"></div>
      <div slot="no-results">
        <div class="background-color-muted w-dyn-empty">
          <div>No results</div>
        </div>
      </div>
    </infinite-loading>
    <b-modal ref="imageModal" id="scroll" scrollable centered size="xl" hide-footer @hide="handleModalClose">
      <template #modal-header>
        <button href="javascript:void(0)" class="close-btn" @click="$refs.imageModal.hide()">
          <b-icon icon="x" aria-label="Close" variant="dark"></b-icon>
        </button>
        <v-container>
          <v-row justify="space-between">
            <v-col :cols="getTitleCols()" sm="12" :md="getTitleCols()" class="text-start">
              <b-media class="d-flex">
                <template #aside>
                  <b-avatar button rounded="sm" :src="selectedImage.user.avatar"></b-avatar>
                </template>
                <div class="lh-sm">
                  <h5 class="m-0 fs-6 lh-sm">{{ selectedImage.user.fullName }}</h5>
                  <small class="m-0 text-muted">{{ selectedImage.user.profession.title }} - {{
                      selectedImage.user.address.fullAddress
                    }}</small>
                </div>
              </b-media>
            </v-col>
            <template v-if="selectedImage.title">
              <v-col :cols="getTitleCols()" sm="12" :md="getTitleCols()" class="text-center"
                     style="white-space: nowrap;">
                <h5 class="m-0 p-0 fs-6">{{ selectedImage.title }}</h5>
              </v-col>
            </template>
            <v-col :cols="getTitleCols()" sm="12" :md="getTitleCols()" class="text-end">
              <template v-if="selectedImage.allowComments">
                <b-button variant="light" @click="getAnchorLink('imageDown')">
                  <b-icon icon="chat-left-dots" font-scale="0.9" class="me-1" aria-label="Like" variant="dark"></b-icon>
                  {{ selectedImage.countComments }}
                  <template v-if="selectedImage.countComments > 1">commentaires</template>
                  <template v-else>commentaire</template>
                </b-button>
              </template>
              <b-button variant="light" @click="getAnchorLink('imageDown')">
                <b-icon icon="info-circle" font-scale="0.9" class="me-1" aria-label="Like" variant="dark"></b-icon>
                Infos
              </b-button>
            </v-col>
          </v-row>
        </v-container>
      </template>

      <viewer-image-common :selectedImage="selectedImage"></viewer-image-common>
      <div id="scroll-imageDown"></div>
    </b-modal>
  </div>
</template>

<script>
import InfiniteLoading from "vue-infinite-loading";

const routes = require("../../../../public/js/fos_js_routes.json");
import Routing from "../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";
import ViewerImageCommon from "../ViewerImageCommonFeed";
import VLazyImage from "v-lazy-image";

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'

Routing.setRoutingData(routes);

export default {
  props: ["profession"],
  components: {
    VLazyImage,
    "viewer-image-common": ViewerImageCommon,
    VueSlickCarousel
  },
  data: function () {
    return {
      page: 1,
      feeds: [],
      infiniteId: +new Date(),
      locale_request: null,
      selectedImage: null,
    };
  },
  mounted: function () {
    this.locale_request = $('html').attr('lang');
    if (typeof imageView !== 'undefined') {
      this.openModal(imageView);
    }
  },
  methods: {
    handleRightClick(event) {
      event.preventDefault();
    },
    getTitleCols() {
      return this.selectedImage.title ? 4 : 6;
    },
    handleModalClose() {
      if (this.profession !== 'all') {
        this.$router.replace({path: '/fr/feed/' + this.profession + '/'});
      } else {
        this.$router.replace({path: '/fr/feed/'});
      }

    },
    openModal(image) {
      this.selectedImage = image;
      this.$refs.imageModal.show();
      if (this.profession !== 'all') {
        this.$router.replace(`/fr/feed/${this.profession}/image/${image.id}`);
      } else {
        this.$router.replace(`/fr/feed/image/${image.id}`);
      }

      $.ajax({
        url: Routing.generate("portfolio_setviewmedias", {
          name: name,
          image: image.id,
        }),
        type: "POST",
        data: image.id,
        success: function (response) {
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log(textStatus, errorThrown);
        },
      });
    },
    getAnchorLink(anchorId) {
      const anchor = document.getElementById(`${this.$refs.imageModal.id}-${anchorId}`);
      if (anchor) {
        anchor.scrollIntoView({behavior: 'smooth'});
      }
    },
    infiniteHandler($state) {
      this.$http
          .get(
              Routing.generate("json_feed_images", {
                page: this.page,
                profession: this.profession,
                locale: this.locale_request
              }),
              {
                params: {
                  page: this.page,
                  profession: this.profession
                },
              }
          )
          .then(({data}) => {
            if (data.feeds.length > 0) {
              this.page += 1;
              this.feeds.push(...data.feeds);
              if (this.page > data.totalPages) {
                $state.complete();
              }
              $state.loaded();
            } else {
              $state.complete();
            }
          });
    },
  },
};
</script>

<style lang="css" scoped src="../modal_feed.css"></style>
<style>
.slick-slide {
  cursor: pointer;
}

.slick-list {
  margin: 0 -5px;
}

.slick-slide > div, .div-block-29, .div-block-32 {
  padding: 0 5px;
}
</style>