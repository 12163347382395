<template>
  <v-app>
    <go-top
        bg-color="#1976d2"
        src="/assets/img/icon-backtotop.png"
        :size="50"
        :has-outline="false"
    ></go-top>
    <section class="section-home-hero">
      <div class="page-padding">
        <div class="padding-top padding-huge">
          <div class="container-xlarge">
            <div class="product-hero-grid">
              <div id="w-node-_1ca4e8a3-8819-7a33-b28a-35e3d813f71b-88ee08b8" class="product-hero-grid-right">
                <div data-w-id="1ca4e8a3-8819-7a33-b28a-35e3d813f71c" class="card muted">
                  <div class="card-padding small">
                    <div class="margin-bottom margin-xsmall">
                      <h4 class="margin-bottom margin-medium">{{ $t('message.search.title', locale) }}</h4>
                    </div>
                    <div class="margin-bottom margin-medium">
                      <div data-w-id="8f9836ef-040c-bb3d-e095-8b19e7622ddb" class="form-wrapper w-form">

                        <div class="form-field-wrapper">
                          <div class="form-label">{{ $t('message.search.profession.label', locale) }}</div>
                          <v-select
                              rounded
                              :clearable="true"
                              :label="$t('message.search.profession.placeholder', locale)"
                              attach
                              v-model="job"
                              :items="jobList"
                              @change="changeJob"
                              item-value="id"
                              item-text="text"
                              class="form-input w-input"
                              single-line
                          >
                          </v-select>
                        </div>
                        <div class="form-field-wrapper">
                          <div class="form-label">{{ $t('message.search.experience.label', locale) }}</div>
                          <v-select
                              auto-select-first
                              rounded
                              :clearable="true"
                              :label="$t('message.search.experience.placeholder', locale)"
                              attach
                              v-model="experience"
                              :items="experience_options[job]"
                              @change="changeType"
                              item-value="id"
                              item-text="text"
                              class="form-input w-input"
                              single-line
                          >
                          </v-select>
                        </div>
                        <div class="form-field-wrapper">
                          <div class="form-label">{{ $t('message.search.location.label', locale) }}</div>
                          <v-autocomplete
                              rounded
                              :clearable="true"
                              attach
                              v-model="autocomplete"
                              :items="items"
                              :loading="isLoading"
                              :search-input.sync="search"
                              item-text="name"
                              item-value="symbol"
                              label="Où ?"
                              @change="changeType"
                              class="form-input w-input"
                              single-line
                          >
                            <template slot="no-data">
                              Commencer à saisir un lieu, puis sélectionner.
                            </template>
                          </v-autocomplete>
                        </div>

                        <h4 class="margin-bottom margin-medium">{{
                            $t('message.search.advancedSearch.title', locale)
                          }}</h4>
                        <template v-if="displayMoreCriteria">
                          <v-switch
                              v-model="sizeGroup"
                              inset
                              @change="changeType"
                              :label="`${$t('message.directory.filters.advancedSearch.mensurations.size.title', locale)}`"
                          ></v-switch>
                          <v-expansion-panels class="mb-5" :disabled="!sizeGroup" flat>
                            <v-expansion-panel>
                              <v-expansion-panel-header
                              > {{ $t('message.directory.filters.advancedSearch.mensurations.size.hint', locale) }}
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <v-range-slider
                                    :disabled="!sizeGroup"
                                    v-model="size"
                                    @change="changeType"
                                    :max="maxSize"
                                    :min="minSize"
                                    hide-details
                                    :thumb-size="24"
                                    thumb-label
                                    class="align-center"/>
                                {{ $t('message.directory.filters.advancedSearch.mensurations.between', locale) }}
                                {{ this.size[0] }}
                                {{ $t('message.directory.filters.advancedSearch.mensurations.and', locale) }}
                                {{ this.size[1] }} cm

                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                          <v-switch
                              v-model="weightGroup"
                              inset
                              @change="changeType"
                              :label="`${$t('message.directory.filters.advancedSearch.mensurations.weight.title', locale)}`"
                          ></v-switch>
                          <v-expansion-panels class="mb-5" :disabled="!weightGroup" flat>
                            <v-expansion-panel>
                              <v-expansion-panel-header
                              >{{ $t('message.directory.filters.advancedSearch.mensurations.weight.hint', locale) }}
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <v-range-slider
                                    :disabled="!weightGroup"
                                    v-model="weight"
                                    @change="changeType"
                                    :max="maxWeight"
                                    :min="minWeight"
                                    hide-details
                                    class="align-center"/>
                                {{ $t('message.directory.filters.advancedSearch.mensurations.between', locale) }}
                                {{ this.weight[0] }}
                                {{ $t('message.directory.filters.advancedSearch.mensurations.and', locale) }}
                                {{ this.weight[1] }} cm
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                          <v-switch
                              v-model="hipGroup"
                              inset
                              @change="changeType"
                              :label="`${$t('message.directory.filters.advancedSearch.mensurations.hip.title', locale)}`"
                          ></v-switch>
                          <v-expansion-panels class="mb-5" :disabled="!hipGroup" flat>
                            <v-expansion-panel>
                              <v-expansion-panel-header
                              > {{ $t('message.directory.filters.advancedSearch.mensurations.hip.hint', locale) }}
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <v-range-slider
                                    v-model="hip"
                                    :disabled="!hipGroup"
                                    @change="changeType"
                                    :max="maxHip"
                                    :min="minHip"
                                    hide-details
                                    class="align-center range-slider mb-3"/>
                                {{ $t('message.directory.filters.advancedSearch.mensurations.between', locale) }}
                                {{ this.hip[0] }}
                                {{ $t('message.directory.filters.advancedSearch.mensurations.and', locale) }} {{
                                  this.hip[1]
                                }} cm
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                          <v-switch
                              v-model="confectionGroup"
                              inset
                              @change="changeType"
                              :label="`${$t('message.directory.filters.advancedSearch.mensurations.confection.title', locale)}`"
                          ></v-switch>
                          <v-expansion-panels class="mb-5" :disabled="!confectionGroup" flat>
                            <v-expansion-panel>
                              <v-expansion-panel-header
                              >{{
                                  $t('message.directory.filters.advancedSearch.mensurations.confection.hint',
                                      locale)
                                }}
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <v-range-slider
                                    v-model="confection"
                                    :disabled="!confectionGroup"
                                    @change="changeType"
                                    :max="maxConfection"
                                    :min="minConfection"
                                    hide-details
                                    class="align-center range-slider mb-3"/>
                                {{ $t('message.directory.filters.advancedSearch.mensurations.between', locale) }}
                                {{ this.confection[0] }}
                                {{ $t('message.directory.filters.advancedSearch.mensurations.and', locale) }}
                                {{ this.confection[1] }} cm
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                          <v-switch
                              v-model="pointureGroup"
                              inset
                              @change="changeType"
                              :label="`${$t('message.directory.filters.advancedSearch.mensurations.pointure.title', locale)}`"
                          ></v-switch>
                          <v-expansion-panels class="mb-5" :disabled="!pointureGroup" flat>
                            <v-expansion-panel>
                              <v-expansion-panel-header
                              >{{ $t('message.directory.filters.advancedSearch.mensurations.pointure.hint', locale) }}
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <v-range-slider
                                    v-model="pointure"
                                    :disabled="!pointureGroup"
                                    @change="changeType"
                                    :max="maxPointure"
                                    :min="minPointure"
                                    hide-details
                                    class="align-center range-slider mb-3"/>
                                {{ $t('message.directory.filters.advancedSearch.mensurations.between', locale) }}
                                {{ this.pointure[0] }}
                                {{ $t('message.directory.filters.advancedSearch.mensurations.and', locale) }}
                                {{ this.pointure[1] }} cm
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </template>
                        <div class="form-field-wrapper">
                          <div class="form-label">
                            {{ $t('message.search.advancedSearch.style.label', locale) }}
                          </div>
                          <v-select
                              rounded
                              :clearable="true"
                              :label="$t('message.search.advancedSearch.style.placeholder', locale)"
                              attach
                              multiple
                              v-model="stylePhotos"
                              :items="stylePhotosList"
                              @change="changeType"
                              item-value="id"
                              item-text="text"
                              single-line
                              class="form-input w-select"
                          ></v-select>
                        </div>
                        <div class="form-field-wrapper">
                          <div class="form-label">
                            {{ $t('message.search.advancedSearch.ethnicity.label', locale) }}
                          </div>
                          <v-select
                              auto-select-first
                              rounded
                              :clearable="true"
                              :label="$t('message.search.advancedSearch.ethnicity.placeholder', locale)"
                              attach
                              v-model="ethnicity"
                              :items="ethnicityList"
                              @change="changeType"
                              item-value="id"
                              item-text="text"
                              single-line
                              class="form-input w-select"
                          >
                          </v-select>
                        </div>
                        <div class="form-field-wrapper">
                          <div class="form-label">
                            {{
                              $t('message.search.advancedSearch.hairColor.label', locale)
                            }}
                          </div>
                          <v-select
                              auto-select-first
                              rounded
                              :clearable="true"
                              :label="$t('message.search.advancedSearch.hairColor.placeholder', locale)"
                              attach
                              v-model="hairs_color"
                              :items="hairsColorList"
                              @change="changeType"
                              item-value="id"
                              item-text="text"
                              single-line
                              class="form-input w-select"
                          >
                          </v-select>
                        </div>
                        <div class="form-field-wrapper">
                          <div class="form-label">
                            {{
                              $t('message.search.advancedSearch.eyesColor.label', locale)
                            }}
                          </div>
                          <v-select
                              auto-select-first
                              rounded
                              :clearable="true"
                              :label="$t('message.search.advancedSearch.eyesColor.placeholder', locale)"
                              attach
                              v-model="eyes_color"
                              :items="eyesColorList"
                              @change="changeType"
                              item-value="id"
                              item-text="text"
                              single-line
                              class="form-input w-select"
                          >
                          </v-select>
                        </div>
                        <div class="form-field-wrapper">
                          <div class="form-label">
                            {{
                              $t('message.search.advancedSearch.sexe.label', locale)
                            }}
                          </div>
                          <v-select
                              auto-select-first
                              rounded
                              :clearable="true"
                              :label="$t('message.search.advancedSearch.sexe.placeholder', locale)"
                              v-model="sexe"
                              :items="sex_options"
                              @change="changeType"
                              item-value="id"
                              item-text="text"
                              single-line
                              class="form-input w-select"
                          >
                          </v-select>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="w-node-_1ca4e8a3-8819-7a33-b28a-35e3d813f725-88ee08b8" class="product-hero-grid-left">
                <template v-if="job">
                  <div class="filter-top-annuaire">
                    <h5 class="margin-bottom">
                      <template v-if="users.length === 0">0 {{ $t('message.search.results.one', locale) }}</template>
                      <template v-else>
                        <template v-if="totalItems > 1"> {{ totalItems }} {{
                            $t('message.search.results.many', locale)
                          }}
                        </template>
                        <template v-else>{{ totalItems }} {{ $t('message.search.results.one', locale) }}</template>
                      </template>
                    </h5>
                    <div class="margin-bottom">
                      <v-switch
                          @change="changeType"
                          inset
                          hide-details
                          class=""
                          v-model="withOnlyImages"
                          :label="`${$t('message.availabilities.hide_empty_books', locale)}: ${withOnlyImages ? $t('message.search.filters.hideEmpty.placeholders.yes', locale) : $t('message.search.filters.hideEmpty.placeholders.no', locale)}`"
                      ></v-switch>
                    </div>
                    <div id="w-node-_4938b433-13e2-87e1-6d85-b8f152ba7396-88ee08b8" class="form-block">
                      <v-select
                          attach
                          hide-details
                          :clearable="true"
                          :label="$t('message.search.filters.sortBy.label', locale)"
                          :items="sort_options"
                          item-value="id"
                          item-text="text"
                          v-model="sort"
                          @change="changeType"
                          outlined
                      ></v-select>
                    </div>
                  </div>
                </template>
                <div class="w-dyn-list">
                  <div role="list" class="annuaire-grid w-dyn-items">
                    <div v-for="(user, $index) in users" :key="$index" role="listitem" class="w-dyn-item">
                      <div id="w-node-_9cd56d24-5299-eafa-2c62-048babbe4559-88ee08b8">
                        <div class="div-block-7">
                          <template v-if="user.identity.isPro">
                            <div class="div-block-8">
                              <div class="label primary">
                                <div>Pro</div>
                              </div>
                            </div>
                          </template>
                          <a :href="user.identity.url" target="_blank" class="link-block w-inline-block">
                            <div id="w-node-f31acf5e-d582-cb4b-8609-3b42aa06e293-88ee08b8"
                                 data-w-id="f31acf5e-d582-cb4b-8609-3b42aa06e293"
                                 style="-webkit-transform:translate3d(0, 0, 0) scale3d(1.1, 1.1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 0, 0) scale3d(1.1, 1.1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 0, 0) scale3d(1.1, 1.1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 0, 0) scale3d(1.1, 1.1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)"
                                 class="profile-picture"
                                 :style="{backgroundImage:`url(${user.identity.avatar})`}"></div>
                          </a>
                          <template v-if="user.identity.availabilities.length > 0">
                            <div class="div-block-5">
                              <a :href="user.identity.link_availability" target="_blank" class="label muted">
                                <div>{{ user.identity.availabilities.length }} {{ $t('message.availabilities.availability', locale) }}</div>
                              </a>
                            </div>
                          </template>
                        </div>
                        <a :href="user.identity.url" target="_blank" class="margin-top margin-small w-inline-block">
                          <div class="div-block-6">
                            <h4 class="heading-h4">{{ user.identity.fullName }}</h4>
                          </div>
                        </a>
                        <h6 class="margin-top">{{ user.identity.experience }}</h6>
                        <div class="text-size-small">{{ user.identity.location }}</div>
                      </div>
                    </div>
                    <template v-if="!query">
                      <infinite-loading
                          :identifier="infiniteId"
                          @infinite="infiniteHandler"
                      >
                        <div slot="no-more"></div>
                        <div slot="no-results">
                          <div class="background-color-muted w-dyn-empty">
                            <div>{{ $t('message.directory.filters.empty', locale) }}</div>
                          </div>
                        </div>
                      </infinite-loading>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </v-app>
</template>

<script>
import axios from "axios";

const routes = require("../../../../public/js/fos_js_routes.json");
import Routing from "../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";
import 'semantic-ui-vue';
import "@mdi/font/css/materialdesignicons.css";
import VueSelect from "vue-select";
import job_options from "../dashboard/datas/job_options.json";
import experience_options from "../dashboard/datas/experience_options.json";
import stylePhotos_options from "../dashboard/datas/stylePhotos_options.json";
import sex_options from "../dashboard/datas/sex_options.json";
import ethnicity_options from "../dashboard/datas/ethnicity_options.json";
import hairs_color_options from "../dashboard/datas/hairs_color_options.json";
import eyes_color_options from "../dashboard/datas/eyes_color_options.json";
import sort_options from "../dashboard/datas/sort_options.json";

Routing.setRoutingData(routes);

export default {
  components: {
    "vue-select": VueSelect
  },
  data: function () {
    let job;
    if (professionSlug === null) {
      job = this.job;
    } else {
      job = professionSlug;
    }
    return {
      job: job,
      experience: null,
      stylePhotos: null,
      sexe: null,
      ethnicity: null,
      job_options: job_options,
      experience_options: experience_options,
      stylePhotos_options: stylePhotos_options,
      sex_options: sex_options,
      ethnicity_options: ethnicity_options,
      hairs_color: null,
      hairs_color_options: hairs_color_options,
      eyes_color: null,
      eyes_color_options: eyes_color_options,
      sort: "relevance",
      sort_options: sort_options,
      totalItems: 0,
      page: 1,
      users: [],
      infiniteId: +new Date(),
      disabled: true,
      isLoading: false,
      items: [],
      autocomplete: null,
      search: null,
      defaultOrder: "relevance",
      locale: null,
      withOnlyImages: false,
      componentForm: {
        street_number: "",
        route: "",
        locality: "",
        administrative_area_level_1: "",
        country: "",
        postal_code: "",
      },
      query: "",
      isChange: false,
      displayMoreCriteria: false,
      sizeGroup: false,
      minSize: 120,
      maxSize: 220,
      size: [120, 220],

      weightGroup: false,
      minWeight: 40,
      maxWeight: 140,
      weight: [40, 140],

      hipGroup: false,
      minHip: 60,
      maxHip: 120,
      hip: [60, 120],

      confectionGroup: false,
      minConfection: 40,
      maxConfection: 140,
      confection: [40, 140],

      pointureGroup: false,
      minPointure: 32,
      maxPointure: 49,
      pointure: [32, 49],
      searchStart: false,

    };
  },
  mounted: function () {
    this.locale = $('html').attr('lang');
  },
  computed: {
    jobList: function () {
      return this.job_options.sort((t1, t2) => t1.text < t2.text ? -1 : 1);
    },
    stylePhotosList: function () {
      return this.stylePhotos_options.sort((t1, t2) => t1.text < t2.text ? -1 : 1);
    },
    ethnicityList: function () {
      return this.ethnicity_options.sort((t1, t2) => t1.text < t2.text ? -1 : 1);
    },
    hairsColorList: function () {
      return this.hairs_color_options.sort((t1, t2) => t1.text < t2.text ? -1 : 1);
    },
    eyesColorList: function () {
      return this.eyes_color_options.sort((t1, t2) => t1.text < t2.text ? -1 : 1);
    },
  },
  watch: {
    job: function (event) {
      this.experience = null;
    },
    search(val) {
      if (!val) {
        return;
      }
      this.isLoading = true;
      const service = new google.maps.places.AutocompleteService();
      service.getQueryPredictions({input: val}, (predictions, status) => {
        if (status != google.maps.places.PlacesServiceStatus.OK) {
          return;
        }
        this.items = predictions.map((prediction) => {
          return {
            id: prediction.id,
            name: prediction.description,
          };
        });

        this.isLoading = false;
      });
    },
  },
  methods: {
    async submitHandler(data) {
      this.loading = true;
      try {
        let response = await axios.post(Routing.generate("users_search_post_front", {query: this.query}))
        this.loading = false;
        this.users = response.data.users;
      } catch (error) {
        this.users = [];
      }
      return this.users;
    },
    infiniteHandler($state) {
      this.isChange = true;
      this.$http
          .get(
              Routing.generate("json_type_artist_all", {
                page: this.page,
              }),
              {
                params: {
                  page: this.page,
                  job: this.job,
                  experience: this.experience,
                  autocomplete: this.autocomplete,
                  withOnlyImages: this.withOnlyImages,
                  sexe: this.sexe,
                  stylePhotos: this.stylePhotos,
                  ethnicity: this.ethnicity,
                  hair_color: this.hairs_color,
                  eyes_color: this.eyes_color,
                  sort: this.sort,
                  size: this.sizeGroup ? this.size : "",
                  weight: this.weightGroup ? this.weight : "",
                  hip: this.hipGroup ? this.hip : "",
                  confection: this.confectionGroup ? this.confection : "",
                  pointure: this.pointureGroup ? this.pointure : "",
                  searchStart: this.searchStart,

                },
              }
          )
          .then(({data}) => {
            this.isChange = false;
            if (data.users.length > 0) {
              this.page += 1;
              this.users.push(...data.users);
              this.totalItems = data.totalItems;
              this.displayMoreCriteria = data.displayMoreCriteria;
              if (this.page > data.totalPages) {
                $state.complete();
              }
              $state.loaded();
            } else {
              $state.complete();
            }

          });
    },
    changeType() {
      this.page = 1;
      this.users = [];
      this.infiniteId += 1;
      this.searchStart = true;
    },
    changeJob() {
      const locale = $('html').attr('lang');
      if (this.job === '1') {
        this.$router.push({path: '/' + locale + '/annuaire/model'})
      } else if (this.job === '2') {
        this.$router.push({path: '/' + locale + '/annuaire/photographer'})
      } else if (this.job === '3') {
        this.$router.push({path: '/' + locale + '/annuaire/make-up-artist-make-up-artist'})
      } else if (this.job === '4') {
        this.$router.push({path: '/' + locale + '/annuaire/comedian-actress'})
      } else if (this.job === '5') {
        this.$router.push({path: '/' + locale + '/annuaire/dancing-dancing'})
      } else if (this.job === '6') {
        this.$router.push({path: '/' + locale + '/annuaire/musician'})
      } else if (this.job === '7') {
        this.$router.push({path: '/' + locale + '/annuaire/videographer'})
      } else if (this.job === '8') {
        this.$router.push({path: '/' + locale + '/annuaire/stylist'})
      } else if (this.job === '9') {
        this.$router.push({path: '/' + locale + '/annuaire/graphic-designer'})
      } else if (this.job === '10') {
        this.$router.push({path: '/' + locale + '/annuaire/author'})
      } else if (this.job === '11') {
        this.$router.push({path: '/' + locale + '/annuaire/artisans'})
      } else if (this.job === '12') {
        this.$router.push({path: '/' + locale + '/annuaire/agency'})
      } else if (this.job === '13') {
        this.$router.push({path: '/' + locale + '/annuaire/singer'})
      } else if (this.job === '14') {
        this.$router.push({path: '/' + locale + '/annuaire/hairdresser-hairdresser'})
      } else if (this.job === '15') {
        this.$router.push({path: '/' + locale + '/annuaire/communications-officer'})
      } else if (this.job === '16') {
        this.$router.push({path: '/' + locale + '/annuaire/painter'})
      } else if (this.job === '17') {
        this.$router.push({path: '/' + locale + '/annuaire/sculptor-artist'})
      } else if (this.job === '18') {
        this.$router.push({path: '/' + locale + '/annuaire/master-of-arts'})
      } else if (this.job === '19') {
        this.$router.push({path: '/' + locale + '/annuaire/dancer-and-model'})
      } else if (this.job === '20') {
        this.$router.push({path: '/' + locale + '/annuaire/dancer-and-model-1'})
      } else if (this.job === '21') {
        this.$router.push({path: '/' + locale + '/annuaire/architect'})
      } else {
        this.$router.push({path: '/' + locale + '/annuaire'})
      }
      this.page = 1;
      this.users = [];
      this.infiniteId += 1;
    },
  },
};
</script>

<style>
.v-application a {
  color: inherit !important;
}

.v-input--selection-controls {
  margin-top: 0 !important;
  padding-top: 0 !important;
}

.v-text-field--rounded > .v-input__control > .v-input__slot {
  padding-left: 0;
  padding-right: 0;
}

.w-dropdown {
  z-index: 7;
}

label {
  text-transform: none;
  margin-bottom: 0;
}

.button-small.button-header.search {
  min-height: 55px;
}

.formulate-input {
  width: 100%;
}

.v-input--switch--inset .v-input--switch__track {
  left: 0;
}

.v-input--switch__thumb {
  margin-left: 5px;
}

.v-input--switch .v-input--selection-controls__input {
  margin-left: 11px;
}
</style>
