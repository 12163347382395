<template>
  <div role="list" class="product3-grid full w-dyn-items">
    <go-top
        bg-color="#36b5aa"
        src="/assets/img/icon-backtotop.png"
        :size="50"
        :has-outline="false"
    ></go-top>
    <div id="w-node-_767e1df4-be0f-71aa-0c2e-785666f70451-bddc40f2" data-w-id="767e1df4-be0f-71aa-0c2e-785666f70451" role="listitem" class="margin-bottom-20 w-dyn-item" v-for="(image, $index) in images"
         :key="$index">
      <div class="image-wrap">
        <a :href="image.user.book" target="_blank" class="label white-label">{{ image.user.fullName }}</a>
        <v-img
            v-on:click="setview(image.id)"
            class="grey lighten-2 image-full"
            loading="lazy"
            v-img="{ src: image.path, title: image.title }"
            :alt="image.title"
            :src="image.thumb_path"
            :lazy-src="image.thumb_path"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </div>
    </div>
  </div>
</template>

<script>
import InfiniteLoading from "vue-infinite-loading";

const routes = require("../../../../public/js/fos_js_routes.json");
import Routing from "../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";

Routing.setRoutingData(routes);

export default {
  components: {},
  data: function () {
    return {
      images: [],
      width: 600,
    };
  },
  mounted() {
    this.$http
        .get(
            Routing.generate("image_inspiration", {
              page: this.page,
            })
        )
        .then(function (response) {
          this.images = response.data;
        });
  },
  methods: {
    setview: function (id) {
      $.ajax({
        url: Routing.generate("portfolio_setviewmedias", {
          name: name,
          image: id,
        }),
        type: "POST",
        data: id,
        success: function (response) {
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log(textStatus, errorThrown);
        },
      });
    },
    /*infiniteHandler($state) {
      this.$http
          .get(
              Routing.generate("image_inspiration", {
                page: this.page,
              }),
              {
                params: {
                  page: this.page,
                },
              }
          )
          .then(({data}) => {
            if (data.images.length > 0) {
              this.page += 1;
              this.images.push(...data.images);
              if (this.page > data.totalPages) {
                $state.complete();
              }
              $state.loaded();
            } else {
              $state.complete();
            }
          });
    },*/
  },
};
</script>

<style>
.label.white-label{
  z-index:1;
}
</style>